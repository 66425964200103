// @file
// The print stylesheet for this theme.

// Colors and Sass
//
// Documentation for colors and Sass mixins and variables.
//
// Weight: -1

@import 'init';

// Components
//
// Weight: 1

@import 'components/print-none/print-none';

.pager,
%pager,
aside,
nav,
footer {
  @extend %print-none;
}

@media print {
  html {
    font-size: $print-font-size;
  }

  body,
  .page {
    background-color: color('white');
  }

  [role='main'] {
    width: 100%;
  }

  // Underline all links.
  :link,
  :visited {
    text-decoration: underline;
  }

  // Add visible title after abbreviations.
  abbr[title] {
    &::after {
      content: ' (' attr(title) ')';
    }
  }
}
